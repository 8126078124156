export default {
  name: 'TimeString',
  template: `
    <span v-show="time.h"><span class="outline-text">{{ time.h }}</span>h</span>
    <span v-show="time.min"><span class="outline-text">{{ time.min }}</span>min</span>
  `,
  props: {
    time: {
      type: Object,
      default: {},
    },
  },
}
