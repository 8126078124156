/* global Vue, GLOSSARY, Swiper, SwiperSlide */

import { Navigation, Pagination } from 'swiper'
import ShowMore from 'scripts/apps/components/ShowMore'
import Accordion from 'scripts/apps/components/Accordion'

const glossaryApp = Vue.createApp({
  name: 'GlossaryApp',
  template: '#glossaryTemplate',
  components: { Swiper, SwiperSlide, Accordion, ShowMore },
  setup () {
    const INITIAL_SHOWING_COUNT = 2
    const showNoAccordions = Vue.ref(INITIAL_SHOWING_COUNT)
    const glossary = Vue.reactive({})
    const selectedLetter = Vue.ref('a')
    const initSwiper = Vue.ref(false)

    const accordions = Vue.computed(() => {
      return glossary[selectedLetter.value]?.slice(0, showNoAccordions.value)
    })

    const selectLetter = (letter) => {
      if (glossary[letter].length) { selectedLetter.value = letter }
      showNoAccordions.value = INITIAL_SHOWING_COUNT
    }

    Vue.onMounted(() => {
      'abcčdefghijklmnoprsštuvzž'.split('').forEach(letter => {
        glossary[letter] = GLOSSARY.filter(definition => {
          return definition.name.charAt(0).toLowerCase() === letter
        })
      })
      initSwiper.value = true
    })

    return {
      glossary,
      accordions,
      selectedLetter,
      selectLetter,
      initSwiper,
      showNoAccordions,
      INITIAL_SHOWING_COUNT,
      swiperModules: [Navigation, Pagination],
    }
  },
})

if (document.getElementById('glossary-app')) {
  glossaryApp.mount('#glossary-app')
}

;
/* global Vue, CONTENT_PAGES  */

const searchApp = Vue.createApp({
  name: 'SearchApp',
  template: '#searchTemplate',
  setup () {
    const showNoOfResultsPerPage = 4
    const searchTerm = Vue.ref('')
    const showSearch = Vue.ref(false)
    const currentPage = Vue.ref(1)
    const showingWhichResults = Vue.ref('')
    const searchPages = Vue.ref([])
    const input = Vue.ref(null)

    const body = document.querySelector('body')
    const mobileMenu = document.querySelector('#mobile-menu')
    const openSearchAppElements = document.querySelectorAll('.search-app-icon')
    openSearchAppElements.forEach(btn => {
      btn.addEventListener('click', e => {
        showSearch.value = true
        body.classList.add('no-overflow')
        mobileMenu.checked = false
        Vue.nextTick(() => {
          input.value.focus()
        })
      })
    })

    const goToPage = (type) => {
      if (type === 'next') {
        currentPage.value = currentPage.value + 1 > Math.ceil(filteredResults.value.length / showNoOfResultsPerPage) ? Math.ceil(filteredResults.value.length / showNoOfResultsPerPage) : currentPage.value + 1
      } else {
        currentPage.value = currentPage.value - 1 < 1 ? 1 : currentPage.value - 1
      }
    }

    const filteredResults = Vue.computed(() => {
      currentPage.value = 1
      if (!searchPages.value || !searchTerm.value) {
        return searchPages.value
      }
      const search = searchTerm.value.toLowerCase()
      return searchPages.value.filter((item) => {
        return checkArray(item, search)
      })
    })

    const filteredResultsToShow = Vue.computed(() => {
      const end = currentPage.value * showNoOfResultsPerPage
      const start = end - showNoOfResultsPerPage
      if (searchTerm.value) {
        showingWhichResults.value = filteredResults.value.length ? `Prikazujem ${start + 1}-${end} od ${filteredResults.value.length} za: ${searchTerm.value}` : `Ni rezultatov za: ${searchTerm.value}`
      } else {
        showingWhichResults.value = 'Niste še vpisali iskane teme.'
      }
      return filteredResults.value.slice(start, end)
    })

    const checkArray = (item, search) => {
      const keys = Object.keys(item)
      let returnVal = false
      for (let ii = 0; ii < keys.length && !returnVal; ii++) {
        if (item[keys[ii]] === null || item[keys[ii]] === undefined) {
          continue
        }
        const varType = typeof item[keys[ii]]
        if (varType === 'string' || varType === 'number') {
          const stringVal = String(item[keys[ii]]).toLowerCase()
          if (stringVal.indexOf(search) !== -1) {
            returnVal = true
          }
        } else if (varType === 'object') {
          returnVal = checkArray(item[keys[ii]], search)
        }
      }
      return returnVal
    }

    const highlightSearchString = (description) => {
      if (!description) return ''

      const regex = new RegExp(searchTerm.value.toLowerCase(), 'ig')
      let parsed = description
      const matches = [...description.matchAll(regex)]
      matches.forEach(match => {
        parsed = description.replaceAll(match, `<span class="button button--tag inline">${match}</span>`)
      })
      return parsed
    }

    Vue.onMounted(() => {
      searchPages.value = CONTENT_PAGES.filter((object, index) => {
        return !object.params.inactive && index === CONTENT_PAGES.findIndex(obj => {
          return obj.url === object.url
        })
      })
    })

    return {
      input,
      searchTerm,
      filteredResults,
      filteredResultsToShow,
      currentPage,
      showNoOfResultsPerPage,
      showingWhichResults,
      showSearch,
      mobileMenu,
      body,
      goToPage,
      highlightSearchString,
    }
  },
})

if (document.getElementById('search-app')) {
  searchApp.mount('#search-app')
}

;
/* global Vue, LOADER, Swiper, SwiperSlide, fillFeatures, VueToast */

import { Navigation as Navigation1, Pagination as Pagination1 } from 'swiper'
import TimeString from 'scripts/apps/components/TimeString'
import InfoNotification from 'scripts/apps/components/InfoNotification'

const modelVehiclesApp = Vue.createApp({
  name: 'modelVehiclesApp',
  components: { Swiper, SwiperSlide, TimeString, InfoNotification },
  template: `
  <section class="more-models">
    <div class="container">
        <Swiper
          class="more-models__tabs"
          v-if="initSwiper"
          :loop="false"
          :modules="swiperModules"
          :slides-per-view="'auto'"
          :space-between="50">
          <SwiperSlide class="more-models__tab"
            v-for="eq in allEquipments"
            @click="selectEquipment(eq)"
            :class="{ 'more-models--active-tab': selectedEquipment === eq.name }">
              <img :src="API_URL + eq.image" :alt="eq.name" />
              <div class="more-models__tabs-content">
                <p class="h3 no-margin">{{ eq.name }}</p>
                <p>že od {{ $filters.formatNumber(eq.price) }} €</p>
              </div>
          </SwiperSlide>
        </Swiper>
    </div>
    <div class="more-models__content-wrapper">
      <div class="more-models__content">
        <div class="container more-models__container">
          <div class="more-models__type-specs-wrapper">
            <div class="input-select">
              <p class="h3 no-margin">Tip vozila</p>
              <select name="car-type">
                <option
                  v-for="vehicle in selectedEqVehicles"
                  :value="vehicle.motor_code"
                  @click="selectVehicle(vehicle)">
                    {{ vehicle.name }}
                </option>
              </select>
              <span class="arrow"></span>
            </div>

            <div class="input-select">
              <p class="h3 no-margin">Čas polnjenja</p>
              <select name="charging-time" v-model="chargeToPercentage">
                <option
                  v-for="chargePercentage in chargePercentages"
                  :value="chargePercentage">
                    Stopnja napolnjenosti do {{ chargePercentage }} %
                  </option>
              </select>
              <span class="arrow"></span>
            </div>

            <div class="more-models__info-cards">
              <div v-show="isSelectedVehicleElektro">
                <p class="no-margin more-models__info-cards-title"><b>NA POTI (125 kW)*</b></p>
                <p class="more-models__info-cards-duration"><TimeString :time="chargingTimes[125]" /></p>
                <p class="no-margin more-models__info-cards-battery">Hitra javna polnilnica (DC) s 125 kW</p>
              </div>

              <div>
                <p class="no-margin"><b>DOMA (2 kW)</b></p>
                <p class="more-models__info-cards-duration"><TimeString :time="chargingTimes[2]" /></p>
                <p class="no-margin">Domača vtičnica (AC) z 2 kW</p>
              </div>

              <div>
                <p class="no-margin"><b>NA POTI (11 kW)</b></p>
                <p class="more-models__info-cards-duration" v-show="isSelectedVehicleElektro"><TimeString :time="chargingTimes[11]" /></p>
                <p class="more-models__info-cards-duration" v-show="!isSelectedVehicleElektro"><TimeString :time="chargingTimes[3.6]" /></p>
                <p class="no-margin">Javna polnilnica (AC) {{ isSelectedVehicleElektro ? 'z 11' : 's 3,6' }} kW</p>
              </div>

              <div>
                <p class="no-margin"><b>DOMA (11 kW)</b></p>
                <p class="more-models__info-cards-duration" v-show="isSelectedVehicleElektro"><TimeString :time="chargingTimes[11]" /></p>
                <p class="more-models__info-cards-duration" v-show="!isSelectedVehicleElektro"><TimeString :time="chargingTimes[3.6]" /></p>
                <p class="no-margin">Domača polnilnica (AC) {{ isSelectedVehicleElektro ? 'z 11' : 's 3,6' }} kW</p>
              </div>
            </div>
            <p><small>* z opcijo hitrega polnjenja</small></p>
          </div>

          <div class="more-models__tech-info">
            <p class="h3">Tehnični podatki</p>
            <div v-if="selectedVehicle">
              <p>
                <span class="more-models__tech-info-title">Največja sistemska moč motorja: </span>
                {{ selectedVehicle.technical_data.kw }} kW ({{ selectedVehicle.technical_data.hp }} KM)
                <span class="icon icon-tooltip" @click="showTooltip()"></span>
               </p>
              <p><span class="more-models__tech-info-title">Poraba el. energije (kombinirano): </span>{{ sloNumber(selectedVehicle.technical_data.consumption_electricity) }} kWh/100km</p>
              <p><span class="more-models__tech-info-title">Gorivo: </span>{{ selectedVehicle.technical_data.fuel }}</p>
              <p><span class="more-models__tech-info-title">Največja hitrost: </span>{{ selectedVehicle.technical_data.max_speed }} km/h</p>
              <p><span class="more-models__tech-info-title">Električni doseg (kombinirano): </span>{{ selectedVehicle.technical_data.reach }} km</p>
              <p><span class="more-models__tech-info-title">Kapaciteta baterije: </span>{{ selectedVehicle.technical_data.battery_capacity ? $filters.formatNumber(selectedVehicle.technical_data.battery_capacity) + ' kWh' : noValue }}</p>
            </div>
          </div>

          <div class="more-models__specials-wrapper">
            <p class="h3">Posebnosti</p>
            <div class="grid no-row-gap">
              <div class="col-12 col-md-6">
                <ul class="list-unordered">
                  <li v-for="highlight in equipmentHighlightsSplit.evens">{{ highlight }}</li>
                </ul>
              </div>
              <div class="col-12 col-md-6">
                <ul class="list-unordered">
                  <li v-for="highlight2 in equipmentHighlightsSplit.odds">{{ highlight2 }}</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="more-models__externals container">
        <a :href="externalLinks.konfigurator.url" class="button">{{ externalLinks.konfigurator.text }}</a>
        <a :href="externalLinks.business_link.url" class="button button--secondary">{{ externalLinks.business_link.text }}</a>
        <a v-if="externalLinks.test_drive" :href="externalLinks.test_drive.url" class="button button--secondary">{{ externalLinks.test_drive.text }}</a>
        <a v-if="externalLinks.price_list" :href="externalLinks.price_list.url" class="button button--secondary">{{ externalLinks.price_list.text }}</a>
        <a v-if="externalLinks.financing_calculator" :href="externalLinks.financing_calculator.url">{{ externalLinks.financing_calculator.text }}</a>
      </div>
    </div>

    <InfoNotification :text="tooltipText" />
  </section>
  `,
  setup () {
    const noValue = 'Ni podatka'
    const initSwiper = Vue.ref(false)
    const apiData = Vue.ref([])
    const allEquipments = Vue.ref([])
    const selectedEquipment = Vue.ref(null)
    const equipmentHighlights = Vue.ref([])
    const equipmentHighlightsSplit = Vue.computed(() => {
      const [evens, odds] = equipmentHighlights.value.reduce((res, item, i) => {
        res[i % 2].push(item)
        return res
      }, [[], []])
      return { evens, odds }
    })
    const selectedEqVehicles = Vue.ref([])
    const selectedVehicle = Vue.ref(null)
    const chargeToPercentage = Vue.ref(80)
    const chargePercentages = [80, 60, 40, 20]
    const externalLinks = Vue.ref({})
    window.MODEL_CONFIG.external_links.forEach(link => {
      if (link.url) externalLinks.value[link.type] = link
    })

    const calculateChargingTime = (power) => {
      const chargingTime = selectedVehicle.value.technical_data.battery_capacity * 0.01 * chargeToPercentage.value /
        (power * 0.9)
      return {
        min: Math.floor((chargingTime - Math.floor(chargingTime)) * 60),
        h: Math.floor(chargingTime),
      }
    }
    const chargingTimes = Vue.computed(() => {
      const values = { 2: {}, 3.6: {}, 11: {}, 125: {} }
      if (chargeToPercentage.value && selectedVehicle.value) {
        [2, 3.6, 11, 125].forEach(power => { values[power] = calculateChargingTime(power) })
      }

      return values
    })

    const sloNumber = (val) => {
      return new Intl.NumberFormat('sl-SI', { maximumFractionDigits: 1 }).format(val)
    }

    const selectEquipment = (model) => {
      selectedEquipment.value = model.name
      equipmentHighlights.value = model.highlights
      selectedEqVehicles.value = apiData.value.filter(modelData => modelData.name === model.name)[0]?.vehicles || []
      if (selectedEqVehicles.value.length) selectVehicle(selectedEqVehicles.value[0])
    }

    const selectVehicle = (vehicle) => {
      selectedVehicle.value = vehicle
    }
    const isSelectedVehicleElektro = Vue.computed(() => (selectedVehicle.value?.technical_data.fuel.toLowerCase() || '') === 'elektro')

    const parseApiResponse = () => {
      const features = {
        price: 5000000,
        rangeMin: 5000000,
        rangeMax: 0,
        kw: 5000000,
      }

      apiData.value.forEach(model => {
        const modelConfig = { name: model.name, image: model.image, price: 0, highlights: model.highlights }
        model.vehicles.forEach(vehicle => {
          modelConfig.price = modelConfig.price ? Math.min(modelConfig.price, vehicle.price) : vehicle.price
          features.rangeMin = Math.min(vehicle.technical_data.reach, features.rangeMin)
          features.rangeMax = Math.max(vehicle.technical_data.reach, features.rangeMax)
          features.kw = Math.min(vehicle.technical_data.kw, features.kw)
        })
        allEquipments.value.push(modelConfig)
        features.price = Math.min(modelConfig.price, features.price)
      })

      if (apiData.value.length && apiData.value[0].vehicles.length) {
        fillFeatures([
          new Intl.NumberFormat('sl-SI', { roundingMode: 'floor', maximumFractionDigits: 0 }).format(features.price) + ' €',
          `${features.rangeMax} km`,
          features.kw + ' kW',
        ])
        selectEquipment(allEquipments.value[0])
      }
    }

    const tooltipText = Vue.ref('')
    const showTooltip = () => {
      tooltipText.value = 'Največja nazivna moč za elektromotorje'
      setTimeout(() => { tooltipText.value = '' }, 20)
    }

    Vue.onMounted(async () => {
      try {
        LOADER.show()
        const response = await fetch(`${window.API_URL}/api/tco-api/e-mobilnost/model-vehicles`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(window.MODEL_CONFIG),
        })
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        const json = await response.json()
        apiData.value = json.records
        parseApiResponse()
      } catch (error) {
        console.log('ooopss.. something went wrong: ', error)
      }
      LOADER.hide()
      initSwiper.value = true
    })

    return {
      noValue,
      initSwiper,
      allEquipments,
      selectedEquipment,
      equipmentHighlightsSplit,
      selectedEqVehicles,
      selectedVehicle,
      chargeToPercentage,
      chargePercentages,
      externalLinks,
      chargingTimes,
      sloNumber,
      selectEquipment,
      selectVehicle,
      isSelectedVehicleElektro,
      tooltipText,
      showTooltip,
      API_URL: window.API_URL,
      swiperModules: [Navigation1, Pagination1],
    }
  },
})

modelVehiclesApp.config.globalProperties.$filters = {
  formatNumber (number) {
    return Intl.NumberFormat('sl-SI', { maximumFractionDigits: 2 }).format(number)
  },
}

if (document.getElementById('model-vehicles-app')) {
  modelVehiclesApp.mount('#model-vehicles-app')
  modelVehiclesApp.use(VueToast)
}

;
/* global Vue, VueToast, InfoNotification */

const createInfoDisclaimerApp = mountEl => Vue.createApp({
  name: 'createInfoDisclaimerApp',
  components: { InfoNotification },
  template: `
    <span class="icon icon-tooltip" @click="showTooltip()"></span>
    <InfoNotification :text="tooltipText" />
  `,
  setup (props) {
    const tooltipText = Vue.ref('')
    const showTooltip = () => {
      tooltipText.value = mountEl.dataset.message
      setTimeout(() => { tooltipText.value = '' }, 20)
    }
    return {
      tooltipText,
      showTooltip,
    }
  },
})

Array.from(document.getElementsByClassName('info-disclaimer-app')).forEach(element => {
  const app = createInfoDisclaimerApp(element)
  app.use(VueToast)
  app.mount(element)
})
